<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/account' }">{{ $lang('tmdDOTco.00652', $route) }}</el-breadcrumb-item>
					<el-breadcrumb-item>{{ $lang('tmdDOTco.00653', $route) }}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			
			<div class="apply-withdrawal" v-loading="loading">
				<div class="apply-wrap">
					<div class="apply-account" v-if="bankAccountInfo.withdraw_type" @click="goAccount()">
						<span class="ns-width">{{ $lang('tmdDOTco.00654', $route) }}</span>
						<div class="apply-account-info">
							<!-- <span v-if="bankAccountInfo.withdraw_type == 'wechatpay'">{{ bankAccountInfo.mobile }}</span> -->
							<span v-if="bankAccountInfo.withdraw_type == 'wechatpay'">{{ $lang('tmdDOTco.00655', $route) }}</span>
							<span v-else>{{ bankAccountInfo.bank_account }}</span>
							<el-image v-if="bankAccountInfo.withdraw_type == 'alipay'" :src="$img('upload/uniapp/member/apply_withdrawal/alipay.png')" fit="contain"></el-image>
							<el-image v-else-if="bankAccountInfo.withdraw_type == 'bank'" :src="$img('upload/uniapp/member/apply_withdrawal/bank.png')" fit="contain"></el-image>
							<!-- <el-image v-else-if="bankAccountInfo.withdraw_type == 'wechatpay'" :src="$img('upload/uniapp/member/apply_withdrawal/wechatpay.png')" fit="contain"></el-image> -->
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="apply-account" v-else @click="goAccount()">
						<span class="ns-width">{{ $lang('tmdDOTco.00656', $route) }}</span>
						<div class="apply-account-info">
							<span class="ns-text-color">{{ $lang('tmdDOTco.00657', $route) }}</span>
						</div>
					</div>
					<div class="apply-account-money demo-input-suffix">
						<span class="ns-width">{{ $lang('tmdDOTco.00658', $route) }}</span>
						<el-input type="number" placeholder="0" v-model="withdrawMoney" :disabled="bankAccountInfo.withdraw_type == 'wechatpay'">
							<template slot="prepend">{{ $lang('components.currency_symbol_left', $route) + $lang('components.currency_symbol_right', $route) }}</template>
						</el-input>
					</div>
					<div class="apply-account-desc">
						<p><span class="ns-width"></span><span>{{ $lang('tmdDOTco.00659', $route) }}</span><span class="balance">{{ $lang('components.currency_symbol_left', $route) + (withdrawInfo.member_info.balance_money | moneyFormat) + $lang('components.currency_symbol_right', $route) }}</span><span @click="allTx">{{ $lang('tmdDOTco.00660', $route) }}</span></p>
						<p><span class="ns-width"></span>{{ $lang('tmdDOTco.00661', $route) + $lang('components.currency_symbol_left', $route) + withdrawInfo.config.min | moneyFormat + $lang('components.currency_symbol_right', $route) + ', ' + $lang('tmdDOTco.00662', $route) + withdrawInfo.config.rate + '%' }}</p>
					</div>
					<div class="apply-account-btn">
						<span class="ns-width"></span>
						<el-button type="primary" size="medium" @click="withdraw" :class="{ disabled: withdrawMoney == '' || withdrawMoney == 0 }" :disabled="bankAccountInfo.withdraw_type == 'wechatpay'">{{ $lang('tmdDOTco.00663', $route) }}</el-button>
						<!-- <router-link to="/member/withdrawal">提现记录</router-link> -->
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
    import { withdrawInfo, accountInfo, withdraw } from "@/api/member/account"
    export default {
        name: "apply_withdrawal",
        components: {},
        data: () => {
            return {
                withdrawInfo: {
                	config: {
                		is_use: 0,
                		min: 1,
                		rate: 0
                	},
                	member_info: {
                		balance_money: 0,
                		balance_withdraw: 0,
                		balance_withdraw_apply: 0
                	}
                },
                bankAccountInfo: {},
                withdrawMoney: '',
                isSub: false,
				loading: true,
				yes: true
            }
        },
		filters: {
			/**
			 * 金额格式化输出
			 * @param {Object} money
			 */
			moneyFormat(money) {
				return parseFloat(money).toFixed(2);
			}
		},
        created() {
            this.getWithdrawInfo();
			this.getBankAccountInfo();
        },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
        methods: {
			/**
			 * 获取提现信息
			 */
			getWithdrawInfo() {
				withdrawInfo().then(res => {
					if (res.code >= 0 && res.data) {
						this.withdrawInfo = res.data;
						if (this.withdrawInfo.config.is_use == 0) {
							this.$router.push('/member/index');
						}
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
				})
			},
			/**
			 * 银行账号信息
			 */
			getBankAccountInfo() {
				accountInfo().then(res => {
					if (res.code >= 0 && res.data) {
						this.bankAccountInfo = res.data;
					}
				}).catch(err => {})
			},
			/**
			 * 全部提现
			 */
			allTx() {
				this.withdrawMoney = this.withdrawInfo.member_info.balance_money;
			},
			/**
			 * 账户列表
			 */
			goAccount() {
				let back = "/member/apply_withdrawal"
				this.$router.push({path: "/member/account_list", query: {back: back}})
			},
			withdraw() {
				if (!this.bankAccountInfo.withdraw_type) {
					this.$message({
						message: this.$lang('tmdDOTco.00664', this.$route) ,
						type: "warning"
					})
					return;
				}
				if (this.withdrawMoney == '' || this.withdrawMoney == 0 || isNaN(parseFloat(this.withdrawMoney))) {
					this.$message({
						message: this.$lang('tmdDOTco.00665', this.$route) ,
						type: "warning"
					});
					return false;
				}
				if (parseFloat(this.withdrawMoney) > parseFloat(this.withdrawInfo.member_info.balance_money)) {
					this.$message({
						message: this.$lang('tmdDOTco.00666', this.$route) ,
						type: "warning"
					});
					return false;
				}
				if (parseFloat(this.withdrawMoney) < parseFloat(this.withdrawInfo.config.min)) {
					this.$message({
						message: this.$lang('tmdDOTco.00667', this.$route) ,
						type: "warning"
					});
					return false;
				}
				
				if (this.isSub) return;
				this.isSub = true;
				
				withdraw({
					apply_money: this.withdrawMoney,
					transfer_type: this.bankAccountInfo.withdraw_type, //转账提现类型
					realname: this.bankAccountInfo.realname,
					mobile: this.bankAccountInfo.mobile,
					bank_name: this.bankAccountInfo.branch_bank_name,
					account_number: this.bankAccountInfo.bank_account
				}).then(res => {
					if (res.code >= 0) {
						this.$message({
							message: this.$lang('tmdDOTco.00668', this.$route) ,
							type: 'success',
							duration: 2000,
							onClose: () => {
								this.$router.push('/member/withdrawal');
							}
						});
					} else {
						this.isSub = false;
						this.$message({
							message: res.message,
							type: "warning"
						});
					}
				}).catch(err => {
					this.isSub = false;
					this.$message({
						message: err.message,
						type: "warning"
					});
				})
			},
        }
    }
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}
	
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
	.apply-withdrawal {
		width: 100%;
		background-color: #FFFFFF;
		
		.apply-wrap {
			display: inline-block;
			width: 500px;
			box-sizing: border-box;
			
			.apply-account {
				display: flex;
				align-items: center;
				cursor: pointer;
				
				.apply-account-info {
					display: flex;
					align-items: center;
					
					span {
						margin-right: 5px;
					}
					
					.el-image {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
			}
			
			.apply-account-money {
				display: flex;
				align-items: center;
				margin-top: 30px;
				
				span {
					flex-shrink: 0;
				}
			}
			
			.apply-account-desc {
				margin-top: 30px;
				text-align: left;
				
				p:first-child {
					span.balance {
						margin-right: 10px;
					}
					span:nth-child(4) {
						color: $base-color;
						cursor: pointer;
					}
				}
				
				p:nth-child(2) {
					color: #999999;
				}
			}
			
			.apply-account-btn {
				margin-top: 30px;
				.el-button {
					margin-right: 20px;
				}
			}
		}
		
		.ns-width {
			display: inline-block;
			width: 115px;
			text-align: right;
		}
	}
</style>
